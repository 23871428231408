import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CartQuantityResponse } from '../../pages/main/interface/cart-quantity-response';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private urlCart = environment.cartUrl;
  constructor(private http: HttpClient) { }

  getCartQuantityFor(cartId: number, vendorId: number):Observable<CartQuantityResponse>{
    const url = `${this.urlCart}/quantity/${cartId}/vendor/${vendorId}`;
    return this.http.get<CartQuantityResponse>(url);
  }

  private cartUpdated = new BehaviorSubject<void>(undefined); // Observable para cuando se actualiza el carrito

  // Observable al cual otros componentes se pueden suscribir
  cartUpdated$ = this.cartUpdated.asObservable();

  // Método para emitir el evento
  notifyCartUpdate() {
    this.cartUpdated.next();
  }
}
